
  

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
// $prefix: "#{$prefix}";
@import "./variables";





:root {
  --bg: #f3f4f8;
  --lightgray: #ebebeb;
  --gray: #878787;
  --primary: #f56620;
  --secondary: #343a40;
  --white: #ffffff;
  --blue: #0a58ca;
  --sky: #5fc3ff;
  --Neonpink: #f5b849;
  --green: #52bb00;
  --ghostwhite: #f8f8fb;
  --text: #343a40;
}

/*scrollbar CSS*/
/* width */

::-webkit-scrollbar {
  width: 3px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: $primary!important;

  &:hover {
    background: #555;
  }
}

/* Handle on hover */

/*scrollbar CSS End*/

/*Tooltip CSS*/

a[data-tooltip] {
  &.top {
    &:before,
    &:after {
      transform: translateY(10px);
    }

    &:hover {
      &:after,
      &:before {
        transform: translateY(0px);
      }
    }
  }

  position: relative;

  &:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: transform 200ms ease, opacity 200ms;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 99;
  }

  &:before {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: transform 200ms ease, opacity 200ms;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 99;
    content: attr(data-tooltip);
    background: #000;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    white-space: nowrap;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &:after {
    width: 0;
    height: 0;
    border: 6px solid transparent;
    content: "";
  }

  &:hover {
    &:after,
    &:before {
      visibility: visible;
      opacity: 0.85;
      transform: translateY(0px);
    }
  }

  &[data-position="top"] {
    &:before {
      bottom: 100%;
      left: -130%;
      margin-bottom: 10px;
    }

    &:after {
      border-top-color: #000;
      border-bottom: none;
      bottom: 101%;
      left: calc(50% - 6px);
      margin-bottom: 4px;
    }
  }
}

/*Tooltip CSS End*/

.#{$prefix}-fs-1 {
  font-size: 36px;
}

.#{$prefix}-fs-2 {
  font-size: 30px;
}

.#{$prefix}-fs-3 {
  font-size: 24px;
  color: #F56620;
}

.#{$prefix}-fs-4 {
  font-size: 18px;
  white-space: nowrap;
}

.#{$prefix}-fs-5 {
  font-size: 14px;
}

.#{$prefix}-fs-6 {
  font-size: 12px;
}

div,
p,
span,
label {
  font-family: "Quicksand", sans-serif !important;
  
}

body {
  font-family: "Quicksand", sans-serif !important;
  background: var(--bg) !important;
  color: var(--text) !important;
}

/*Header CSS*/

.navbar {
  height: 75px;
  border-bottom: 1px solid $lightgray;
  background: $white;
  position: fixed;
  z-index: 11;
  font-family: "Quicksand", sans-serif !important;
  top: 0;
}

.#{$prefix}-pt-6 {
  padding-top: 75px;
}

.#{$prefix}-logo-box {
  width: 7%;
  text-align: center;
  margin: 0 !important;
  border-right: 1px solid $lightgray;
  padding-right: 5px !important;
}

.#{$prefix}-logo {
  width: 50px;
}

.#{$prefix}-brand-logo-box {
  width: 10%;
  text-align: center;
  margin: 0 !important;
  border-right: 1px solid $lightgray;
  padding: 10px !important;
}

.#{$prefix}-brand-logo {
  width: auto;
  height: 50px;
}

.navbar-nav {
  .nav-link.active,
  .show > .nav-link {
    background: $primary !important;
    text-align: center;
    color: $white !important;
  }

  .nav-link.active svg path {
    fill: $white !important;
  }
}

.navbar-collapse {
  margin-left: 10px;
}

.navbar-nav .nav-link {
  color: $gray !important;
  font-size: 12px;
  height: 75px;
  padding-top: 10px;
  text-align: center;
  font-weight: 500;
}

.dropend .dropdown-toggle {
  color: $primary;
  margin-left: 1em;
}

.dropdown-item {
 font-weight: 500!important;
}

.dropdown-item:hover {
  color: var(--ghostwhite)!important;
  background-color: var(--primary)!important;
}

.dropdown-item-textblack:hover {
  color: $secondary;
}

.dropdown-menu {
  z-index: 10;
}
.dropend .dropdown-menu {
  margin-top: 0.125em;
  margin-left: 0.125em;
}

.nav-link > {
  label {
    margin-top: 5px;
    width: 75px;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-top: 5px;
  }

  img {
    width: 24px;
    height: 24px;
    margin-top: 5px;
  }
}

.#{$prefix}-i-btn {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  color: $white;
  background: $primary;
  border: 0;
  font-weight: 800;
  font-size: 24px;
  margin-top: 5px;
}

.#{$prefix}-header-divider {
  border-right: 4px solid $primary;
  margin: 5px 10px 0;
}

.#{$prefix}-profile {
  width: 40px !important;
  height: 40px !important;
  border-radius: 65px !important;
}

.#{$prefix}-profile-img {
  width: 32px !important;
  height: 32px !important;
  border-radius: 65px !important;
}

.#{$prefix}-pdf-icon {
  border-radius: 0px !important;
}

.#{$prefix}-ellipsis-text {
  overflow: hidden;

  text-overflow: ellipsis;
  width: 74px;
}

.#{$prefix}-header-icon {
  width: 24px;
  height: 24px;
  margin: 10px;
}

.#{$prefix}-profile-arrow-icon {
  width: 24px;
  height: 24px;
  margin: 10px 0 !important;
}

.dropdown .#{$prefix}-profile-dropdown {
  margin-top: 1em !important;
  margin-left: -6em !important;

  &-item{
    padding: 0!important;
    
  li {
    
  }
    &-item{
      padding: 0!important;
     
    } 
  }
}

.#{$prefix}-plus-icon {
  margin-bottom: 4px;
}

.#{$prefix}-submenu {
  > {
    img {
      width: 20px !important;
      height: 20px !important;
    }

    label {
      font-size: 10px;
    }
  }

  height: 60px !important;
}

.navbar-toggler {
  background: $primary;
}

.#{$prefix}-desk-hide {
  display: none;
}

.dropdown-toggle::after {
  display: none;
}

/*Header CSS END*/

/*Silder CSS*/
.#{$prefix}-left-box {
  .#{$prefix}-splide-controls {
    .splide__arrow {
      position: unset;
      background: transparent;
      transform: unset;
      height: unset;

      svg {
        fill: transparent;
        transform: unset;
      }
    }

    .splide__toggle {
      margin: 0 1rem;
    }

    .splide__arrow--next {
      svg {
        fill: transparent;
        transform: unset;
      }
    }
  }
}

.#{$prefix}-left-right-box {
  height: 84vh;
}

.intelligent_insights_first {
  background: $primary;
  border-radius: 12px;
  height: 40vh;
  margin-bottom: 5%;
}

.intelligent_insights_two {
  background: $white;
  border-radius: 12px;
  // height: 32vh;
}

.#{$prefix}-colume_title {
  font-size: 16px;
  font-weight: 700;
  margin: 5px 15px;
  font-family: "Quicksand", sans-serif !important;
}

.#{$prefix}-colume_title {
  font-size: 16px;
  font-weight: 700;
  margin: 15px;
  font-family: "Quicksand", sans-serif !important;
}

/*Silder CSS*/

/*Main Scorll*/

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.#{$prefix}-main-scroll {
  position: absolute;
  left: 25.3%;
}

/*.#{$prefix}-left-box{
    position: fixed;
    left: 2%;
}
.#{$prefix}-right-box{ 
    position: fixed;
    right: 2%; 
}*/
.#{$prefix}-box-right {
  position: fixed;
  right: 2%;
  width: 22.5%;
}

.color-primary {
  color: $primary !important;
}

.color-white {
  color: $white !important;
}

.#{$prefix}-title {
  color: #343a40;
  font-size: 14px;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 600;
  word-wrap: break-word;
  white-space: nowrap;
}

.#{$prefix}-side-img {
  width: 50%;
  border-radius: 6px;
  border: 3px solid $primary;
  margin: 0 20%;
}

.#{$prefix}-side-scroll {
  overflow: auto;
  height: 60vh;
}

.#{$prefix}-input-box {
  width: 100%;
  height: 45px;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 5px 15px;
}

.#{$prefix}-sthought-input-box {
  width: 100%;
  height: 200px;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 10px 15px;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.#{$prefix}-icon_box {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  margin-left: 15px;
  border: 1px solid #ebebeb;
  background: #fff;
}

.#{$prefix}-height-45 {
  height: 45px;
}

.#{$prefix}-info-btn {
  padding: 8px 30px;
  border-radius: 32px;
  border: 0;
  font-weight: 600;
  font-size: 14px;
  background: #00a6ed;
  color: #fff;
}

.#{$prefix}-margin-left-10 {
  margin-left: 10%;
}

.#{$prefix}-button {
  width: 100%;
  height: 45px;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 5px 15px;
  background: $primary;
}

.left-menu,
.right-menu {
  display: none;
}

.#{$prefix}-fixed {
  position: fixed;
}

.input-group .btn {
  position: absolute;
  z-index: 0 !important;
  right: 0 !important;
  border-left: 0 !important;
  margin-left: -1px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.#{$prefix}-scroll-title {
  padding: 0.2rem 0.5rem;
}

/*Main Scorll*/

/*Footer CSS*/

.clider-v2-footer {
  position: fixed;
  width: 100%;
  background: #fbfaff;
  border-top: 1px solid #ebebeb;
  height: 30px;
  bottom: 0;
}

.#{$prefix}-left-footer,
.#{$prefix}-right-footer {
  width: 50%;
  padding-top: 2.5px;
}
.clide-v2-section-info{
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: #fff;
}

.#{$prefix}-left-footer-icon {
  border-right: 1px solid #878787;
  padding: 0 15px;
}

.#{$prefix}-right-footer-icon {
  border-left: 1px solid #878787;
  padding: 0 15px;
}

.#{$prefix}-left-footer-icon {
  border-right: 1px solid #878787;
  padding: 0 15px;

  .top {
    border-bottom: 0 !important;
  }
}

.#{$prefix}-right-footer-icon {
  border-left: 1px solid #878787;
  padding: 0 15px;

  .top {
    border-bottom: 0 !important;
  }
}

/*Footer CSS End*/

//Live Feed Css

/*Intelligent insights*/
.#{$prefix}-intelligent-insights {
  font-size: 50px;
}

.image-intelligent-insights {
  position: absolute;
  right: 10px;
  margin-top: 40%;
}

.achievement-img {
  border-bottom-left-radius: 90px;
  border-top-right-radius: 12px;
  border-left: 4px solid #f56620;
  border-bottom: 4px solid #f56620;
  height: 200px;
  object-fit: cover;
}
.responsive-bg-w-text > .header_2 {
  display: inline;
  margin-top: 26%;
  font-size: calc(0.8em + 0.4rem);
  font-weight: bold;
  text-align: left;
  padding: 0 5%;
  color: var(--primary);
}
.achievement-text {
  font-size: 27px;
}

// upload-modal styles

#dropBox {
  min-height: 150px;
  border: 3px solid var(--primary);
  text-align: center;
  background: var(--bs-body-bg);
  padding: 1em;
  margin: auto;
  border-radius: 12px;
  border-style: dotted;
}

#dropBox.hover {
  border-color: darkslateblue;
  background: aliceblue;
}

.button {
  display: inline-block;
  padding: 0.5em 1em;
  background: black;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid darkslateblue;
  color: #fff;
  transition: 0.4s;
}

.button:hover {
  background: darkslateblue;
}

#imgUpload {
  display: none;
}

#gallery {
  text-align: center;
  margin-top: 1.5em;
}

#gallery div {
  display: inline-block;
  margin: 0.5em 1em;
}

#gallery img {
  height: 160px;
  width: 160px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}

#gallery div p {
  margin: 0;
  position: absolute;
  margin-top: -4%;
  text-align: center;
  width: 160px;
  color: #fff;
  background: #0000008a;
  padding: 5px 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.image-file-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  text-align: center;
  width: 150px;
  margin-top: -35px;
  background: #00000078;
  padding: 5px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #fff;
}

#gallery .fSize {
  font-size: 0.8em;
}

#gallery .fType {
  font-size: 0.7em;
}

.#{$prefix}-live-feed-tabs {
  display: flex;
  gap: 1rem;

  .#{$prefix}-live-feed-tabs-item {
    button {
      background: #ffffff;
      border-width: 1px 1px 0px 1px;
      border-style: solid;
      border-color: $primary;
      border-radius: 12px 12px 0px 0px;
      font-weight: 600;
      color: $primary;
      line-height: 18px;
      width: 11rem;
      height: 40px;

      svg {
        margin-right: 0.2rem;

        path {
          fill: $primary;
        }
      }
    }

    .feed-tab-active {
      background: $primary;
      color: $white;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}

//Live Feed Css End here

//Forms styles like audit , ptw etc

.#{$prefix}-quote {
  background: #fbfaff;
  border: 1px solid #a4a1a0;
  border-radius: 12px;
  height: auto;
  overflow: hidden;
}

// Media Quries Section
@media print {
  .#{$prefix}-scroll-bar {
    overflow: visible !important;
    height: auto !important;
  }
}
@media screen and (max-width: 600px) {
  .nav-box {
    justify-content: flex-start !important;
    background: #fffefe;
    width: 100%;
    z-index: 99;
  }

  .#{$prefix}-i-btn,
  .navbar-toggler {
    margin-left: 5%;
  }

  .#{$prefix}-brand-logo-box,
  .#{$prefix}-logo-box {
    width: 30%;
  }

  br {
    display: none;
  }

  .nav-link > label {
    margin-top: 5px;
    width: 75px;
    font-size: 16px;
    padding-top: 5px;
    margin-left: 10px;
  }

  .navbar-nav .nav-link {
    color: $gray !important;
    font-size: 12px;
    height: 55px;
    padding-top: 5px;
    text-align: left;
    font-weight: 500;
  }

  .nav-link > svg {
    width: 24px;
    height: 24px;
    margin-top: -5px;
    margin-left: 30px;
  }

  .nav-link > img {
    width: 24px;
    height: 24px;
    margin-top: -5px;
    margin-left: 30px;
  }

  .navbar-nav {
    .nav-link.active,
    .show > .nav-link {
      background: $primary !important;
      text-align: left;
      color: $white !important;
    }
  }

  .#{$prefix}-submenu {
    > {
      label {
        font-size: 10px !important;
        text-align: center !important;
        width: 50px !important;
      }

      svg {
        width: 20px !important;
        height: 20px !important;
        margin-left: 10px !important;
      }
    }

    height: 60px !important;
    width: 60px;
    text-align: center !important;
  }

  .navbar-nav {
    margin-top: 5%;
  }

  .#{$prefix}-header-action-mob {
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .dropdown .#{$prefix}-profile-dropdown {
    margin-top: -9em !important;
    margin-left: -1em !important;
  }

  .#{$prefix}-mob-hide {
    display: none;
  }

  .#{$prefix}-header-icon {
    width: 24px;
    height: 24px;
    margin: 10px 15px;
  }

  .#{$prefix}-header-divider {
    border-right: 4px solid $primary;
    margin: 0 20px;
  }

  //nabar end

  .#{$prefix}-info-btn {
    padding: 8px 16px;
    border-radius: 32px;
    border: 0;
    font-weight: 600;
    font-size: 12px;
    background: #00a6ed;
    color: #fff;
  }

  .#{$prefix}-scroll-title {
    padding-left: 0.5rem !important;
    padding: 0;
    font-size: 14px;
  }

  b,
  div,
  label {
    font-size: 14px;
    white-space: break-spaces;
  }

  .#{$prefix}-margin-left-10 {
    margin-left: 0;
  }

  .#{$prefix}-left-box,
  .#{$prefix}-right-box {
    display: none;
  }

  .left-menu,
  .right-menu {
    display: block;
  }

  //live page end herer
  .#{$prefix}-main-scroll {
    position: inherit;
  }

  .horizontal-nav-tab-wrapper .tab-item {
    font-size: 0.6rem !important;
  }
  /*bootstrap CSS Mobile*/
  .#{$prefix}-p-0 {
    padding: 0 !important;
  }
  .#{$prefix}-p-5 {
    padding: 0.5rem !important;
  }
  .#{$prefix}-py-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .#{$prefix}-ps-5 {
    padding-left: 0 !important;
  }
  .#{$prefix}-pe-5 {
    padding-right: 0 !important;
  }
  .#{$prefix}-scroll-bar {
    /* height: auto; */
    overflow: auto;
  }
  .#{$prefix}-page-help {
    position: fixed;
    right: -53px;
    top: 20%;
    background: #f56620;
    color: #fff;
    transform: rotate(-90deg);
    padding: 1px 15px 1px 15px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .#{$prefix}-scroll-bar {
    height: auto !important;
    overflow: auto;
  }
  .#{$prefix}-card-box {
    background: #fbfaff;
    border: 1px solid #a4a1a0;
    border-radius: 12px;
    height: auto;
    overflow: hidden;
  }
  .#{$prefix}-bullet {
    width: 35px !important;
    height: 20px;
    box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
    margin-left: -11px;
    margin-top: 5px;
  }
  /*bootstrap CSS Mobile*/
  //main scroll end here

  .#{$prefix}-location-width {
    display: none;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  //navbar
  .nav-box {
    justify-content: flex-start !important;
    background: #fffefe;
    width: 100%;
    z-index: 99;
  }

  .#{$prefix}-brand-logo-box,
  .#{$prefix}-logo-box {
    width: 20%;
  }

  .#{$prefix}-i-btn,
  .navbar-toggler {
    margin-left: 5%;
  }

  //main scroll
  .#{$prefix}-main-scroll {
    position: absolute;
    left: 32%;
  }

  //live page
  .intelligent_insights_first {
    background: $primary;
    border-radius: 12px;
    height: 25vh;
    margin-bottom: 5%;
  }

  .intelligent_insights_two {
    border-radius: 12px;
    height: 19vh;
  }

  .#{$prefix}-left-right-box {
    height: 42vh;
  }

  .#{$prefix}-box-right {
    position: inherit;
    width: 100%;
  }

  .#{$prefix}-side-scroll {
    overflow: auto;
    height: 23vh;
  }
  
  .#{$prefix}-location-width {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .dropend {
    .dropdown-menu {
      position: absolute;
      top: 0;
      left: 100%;
    }

    .dropdown-toggle {
      margin-left: 0.5em;
    }
  }
}

// Media Quries Section End

//common classes
.cursor-p {
  cursor: pointer;
}
.w-20 {
  width: 20%;
}

.w-80 {
  width: 80%;
}

.w-35 {
  width: 35%;
}

.w-65 {
  width: 65%;
}

.padding-1_2 {
  padding: 1.2em;
}

.#{$prefix}-escalation-layout {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #f56620;
  color: #f56620;
  padding: 3.5px;
  cursor: pointer;
  background: transparent;
  height: fit-content;
}

.#{$prefix}-border-color,
input {
  border-color: #ced4da;
}

.#{$prefix}-icon-btn {
  background: transparent;
  padding: 0;
  border: unset;
}

.#{$prefix}-active {
  background: var(--primary) !important;
  color: var(--white) !important;
}

.#{$prefix}-avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.#{$prefix}-common-white-1 {
  color: $ghostwhite;
}

.#{$prefix}-oreange-1 {
  color: $primary;
}

.#{$prefix}-common-border-b-orange-1 {
  border-bottom: 1px solid $primary;
}

.#{$prefix}-common-white-bg-1 {
  background-color: $ghostwhite;
}

.#{$prefix}-common-orange-bg-1 {
  background-color: $primary;
}

.#{$prefix}-bg {
  background: var(--bg);
}

.#{$prefix}-lightgray {
  background: var(--lightgray);
}

.#{$prefix}-gray {
  background: var(--gray);
}

.#{$prefix}-primary {
  background: var(--primary) !important;
}

.#{$prefix}-secondary {
  background: var(--secondary);
}

.#{$prefix}-white {
  background: var(--white);
}

.#{$prefix}-blue {
  background: var(--blue);
}

.#{$prefix}-sky {
  background: var(--sky);
}

.#{$prefix}-Neonpink {
  background: var(--Neonpink);
}

.#{$prefix}-green {
  background: var(--green);
}

.#{$prefix}-ghostwhite {
  background: var(--ghostwhite);
}

.#{$prefix}-red {
  background: var(--red);
}

.#{$prefix}-text-color {
  color: var(--text);
}

.#{$prefix}-bg-color {
  color: var(--bg);
}

.#{$prefix}-lightgray-color {
  color: var(--lightgray);
}

.#{$prefix}-gray-color {
  color: var(--gray);
}



.#{$prefix}-secondary-color {
  color: var(--secondary) !important;
  :hover {
    color: var(--secondary) !important;
  }
}

.#{$prefix}-white-color {
  color: var(--white) !important;
}

.#{$prefix}-blue-color {
  color: var(--blue);
}

.#{$prefix}-sky-color {
  color: var(--sky);
}
.#{$prefix}-primary-color {
  color: var(--primary) !important;
  :hover {
    color: var(--primary) !important;
  }
}

.#{$prefix}-Neonpink-color {
  color: var(--Neonpink);
}

.#{$prefix}-green-color {
  color: var(--green);
}

.#{$prefix}-ghostwhite-color {
  color: var(--ghostwhite);
}

.#{$prefix}-red-color {
  color: var(--red);
}

.#{$prefix}-border-bg {
  border-color: var(--bg) !important;
}

.#{$prefix}-border-lightgray {
  border-color: var(--lightgray) !important;
}

.#{$prefix}-border-gray-color {
  border-color: var(--gray) !important;
}

.#{$prefix}-border-primary-color {
  border-color: var(--primary) !important;
}

.#{$prefix}-border-secondary-color {
  border-color: var(--secondary) !important;
}

.#{$prefix}-border-white-color {
  border-color: var(--white) !important;
}

.#{$prefix}-border-blue-color {
  border-color: var(--blue) !important;
}

.#{$prefix}-border-sky-color {
  border-color: var(--sky) !important;
}

.#{$prefix}-border-Neonpink-color {
  border-color: var(--Neonpink) !important;
}

.#{$prefix}-border-green-color {
  border-color: var(--green) !important;
}

.#{$prefix}-border-ghostwhite-color {
  border-color: var(--ghostwhite) !important;
}
.#{$prefix}-btn-template {
  background: var(--white);
  border: 1px solid #ddd;
  padding: 5px; 
  font-weight: 600;
  font-size: 14px;
  border-radius: 35px;
  color: var(--white);
  height: 40px;
}

.#{$prefix}-colume_title {
  font-size: 16px;
  font-weight: 700;
  margin: 15px;
  font-family: "Quicksand", sans-serif !important;
}

.#{$prefix}-white-box {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 12px;

  a {
    &:hover {
      color: currentColor;
    }
  }
}

//packages customize

//react calender

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: $white;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  height: 38px;
  width: 38px;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  color: $primary;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: $primary;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: $secondary;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: $primary;
  border-radius: 40%;
}

.react-calendar__tile--now {
  background: #646269;
  border-radius: 70%;
  font-weight: bold;
  color: #fff;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: $primary!important;
  border-radius: 70%;
  font-weight: bold;
  color: $primary;
}

.react-calendar__tile--hasActive {
  background: $primary!important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: $primary!important;
  border-radius: 70%;
  font-weight: bold;
  color: $white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $primary!important;
  color: $white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #bfb3e8;
  color: $primary;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-radius: 70%;
  background: $primary!important;
  color: $white;
}

.react-calendar__tile--rangeEnd {
  border-radius: 70%;
  background: $primary!important;
  color: $white;
}

//nmpage

.#{$prefix}-outer-legend-block {
  display: flex;
  flex-direction: row;
}
.#{$prefix}-bullet {
  width: 22px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 50%;
  background: #fff;
  margin-left: -11px;
  margin-top: 5px;
}
.#{$prefix}-so-kanban-inner-legend-block {
  display: flex;
  align-items: center;
}

.#{$prefix}-legend-number {
  margin-left: auto;
  /* This will push the number to the right */
}

.#{$prefix}-soi-kanban-modal-legend-box {
  width: 20px;
  /* adjust width as needed */
  height: 20px;
  /* adjust height as needed */
  display: inline-block;
}

.#{$prefix}-preview-box {
  margin-right: 10px;
  /* Adjust spacing between preview boxes */
  margin-bottom: 10px;
}

.#{$prefix}-preview-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  /* Add margin to create separation from the buttons */
}

/* Detail page */

.#{$prefix}-card-box-1 {
  background: #fbfaff;
  border: 1px solid #a4a1a0;
  border-radius: 12px;
}

.line_box {
  display: flex;
  margin-bottom: 40px;
}

.text_circle {
  flex: 1;
  text-align: center;
  position: relative;
  min-width: 18rem;
  max-width: 25rem;
}

.text_circle:after {
  background-color: grey;
  bottom: 1.25em;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  right: 0;
  width: 50%;
  z-index: 0;
  top: -20px;
}

.stav_projektu .text_circle:after,
.stav_projektu .text_circle:before {
  background-color: grey;
}

.line_box h4 {
  color: var(--primary);
  font-weight: bold;
}

.line_box h4,
.line_box p {
  font-size: 18px;
  margin-bottom: 0;
  padding: 0 5px;
}

.subline {
  position: absolute;
  right: -25px;
  bottom: -43%;
}

.subline:before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  bottom: 14px;
  right: 15px;
  background-color: var(--primary);
  border-radius: 50%;
  top: -25px;
}

.subline h6 {
  margin-bottom: 0;
}

.timeline {
  margin: 1.5rem 0;
}

.text_circle.done:after,
.text_circle.done + .text_circle:before,
.stav_projektu .text_circle.done:after,
.stav_projektu .text_circle.done + .text_circle:before {
  background-color: var(--primary);
}

.text_circle.sub:after {
  background-color: var(--primary);
}

.text_circle:not(:first-child):before {
  bottom: 1.25em;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  left: 0;
  width: 50%;
  z-index: 0;
  background-color: grey;
  top: -20px;
}

.stav_projektu .text_circle:not(:first-child):before {
  background-color: grey;
}

.text_circle:last-child:after {
  width: 0;
}

.circle {
  text-align: center;
  margin: 0 5%;
  padding: 5%;
  .text-left {
    width:100% ;
    .font-weight-500 {
      @extend .text-overflow-ellipsis
    }
  }
}

.tvar {
  height: 40px;
  width: 40px;
  border: 2px solid var(--primary);
  display: flex;
  position: relative;
  border-radius: 100%;
  top: -43px;
  margin: 3px auto;
  background-color: #fff;
  z-index: 1;
}

.tvar span {
  margin: 25% auto;
  height: 20px;
  width: 20px;
  background-color: var(--primary);
  border-radius: 100%;
  color: #fff;
}

.sectionClass {
  padding: 50px 0px 50px 0px;
  position: relative;
  display: block;
  border-radius: 12px;
}

.sectiontitle {
  background-position: center;
  text-align: center;
  min-height: 20px;
}

.sectiontitle h2 {
  font-size: 30px;
  color: #222;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
}

.headerLine {
  width: 160px;
  height: 2px;
  display: inline-block;
  background: #101f2e;
}

.fullWidth {
  width: 100%;
  display: block;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
}

/********************************/
/*  SECTION WORK EXPERIENCE
  ********************************/

#work-experience .sectiontitle .headerLine {
  width: 280px;
}

#work-experience .headerline {
  width: 280px;
}

.cbp_tmtimeline {
  margin: 60px 30px 0 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.cbp_tmtimeline:before {
  content: "";
  position: absolute;
  top: 2%;
  bottom: 0;
  width: 5px;
  background: var(--primary);
  left: 13%;
  height: 100%;
}

.cbp_tmtimeline li:last-child:before {
  content: initial;
}

.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 25%;
  padding-right: 100px;
  position: absolute;
}

.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: right;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  font-size: 0.9em;
  color: #bdd0db;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  font-size: 2.9em;
  color: #3594cb;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
  color: #6cbfee;
}

.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 15px 20%;
  padding: 15px;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4;
  font-family: "Open Sans";
  position: relative;
  border-radius: 5px;
  min-height: 150px;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
  background: var(--white);
}

.cbp_tmtimeline > li .cbp_tmlabel h3 {
  margin-top: 0px;
  color: white;
  font-size: 20px;
  margin-bottom: 5px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.cbp_tmtimeline > li .cbp_tmlabel h4 {
  opacity: 0.7;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 600;
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  text-align: left;
}

.cbp_tmtimeline > li .cbp_tmlabel h4 i {
  margin-right: 5px;
  vertical-align: middle;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: var(--white);
}

.cbp_tmtimeline > li .cbp_tmicon {
  width: 50px;
  height: 50px;
  top: 7%;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #151515;
  background: var(--primary);
  border-radius: 50%;
  text-align: center;
  left: 13%;
  margin: 0 0 0 -20px;
}

.cbp_tmtimeline li {
  margin-bottom: 70px;
  position: relative;
}

.sectionClassProject {
  position: relative;
  display: block;
  /* background: #f7f7f7; */

  margin: 0 auto;
  padding: 80px 1.875em 3.125em;
}

.projectParagraph {
  font-size: 18px;
  margin: 0.5em 0 0;
  font-family: "Source Sans Pro", serif;
}

.projectParagraphLink {
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-top: 50px !important;
  margin-bottom: 0px;
  text-align: right;
}

.projectParagraphLink a {
  color: white;
  text-decoration: underline;
}

.cbp_tmicon svg {
  margin: 15px 0;
}

.faPra {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 30px;
  vertical-align: middle;
  color: white;
  line-height: 80px;
}

.label {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  color: #ffffff;
  display: inline;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
  padding: 5px 15px;
}

.date {
  color: #bfc3c7;
  display: block;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 30px;
  right: 20px;
}

.date i {
  margin-right: 8px;
  vertical-align: top;
  font-size: 18px;
  line-height: 20px;
}

@media (max-width: 1024px) {
  .cbp_tmtimeline:before {
    display: none;
  }

  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }

  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left;
  }

  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 30px 0 70px 0;
    padding: 50px 30px 30px 30px;
    font-weight: 400;
    font-size: 95%;
    float: left;
  }

  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    border-right-color: transparent;
    border-bottom-color: rgb(50, 68, 84);
    top: -20px;
  }

  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: rgb(43, 58, 72);
    left: 65px;
  }

  .cbp_tmtimeline > li:nth-child(even) .cbp_tmlabel:after {
    right: 65px;
  }

  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmicon {
    position: relative;
    float: left;
    left: auto;
    margin: 0px 5px 0 0px;
  }

  .cbp_tmtimeline > li:nth-child(even) .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: 0px 5px 0 0px;
  }

  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 1.5em;
  }
}

@media (max-width: 32em) {
  .cbp-ntaccordion {
    font-size: 70%;
  }
}

.stav_projektu .tvar {
  border: 2px solid grey;
}

.stav_projektu .done .tvar,
.stav_projektu .sub .tvar {
  border: 2px solid var(--primary);
}

.subline h6 {
  color: var(--primary);
}

.timeline .card-header:hover {
  background-color: #ececec;
  cursor: pointer;
}

.about-me-img {
  width: 120px;
  height: 120px;
  left: 10px;
  /* bottom: 30px; */
  position: relative;
  border-radius: 100px;
}

.authorWindow {
  width: 600px;
  background: #75439a;
  padding: 22px 20px 22px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.authorWindowWrapper {
  display: none;
  left: 110px;
  top: 0;
  padding-left: 25px;
  position: absolute;
}

.trans {
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media screen and (max-width: 768px) {
  .authorWindow {
    width: 210px;
  }

  .authorWindowWrapper {
    bottom: -170px;
    margin-bottom: 20px;
  }
}

/* iPhone X ----------------------------------- */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .subline:before {
    top: -43px;
  }
}

@media only screen and (device-width: 812px) and (device-height: 375px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  .subline:before {
    top: -31px;
  }
}

/* iPad portrait ----------------------------------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .subline:before {
    top: -29px;
  }
}

/* mobile width till 767px ----------------------------------- */
@media (max-width: 767px) {
  .subline:before {
    top: -30px;
  }

  .overflow-x {
    width: 100%;
    overflow-x: auto;
  }

  .mob-pe {
    padding-right: 50px !important;
  }

  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmicon,
  .cbp_tmtimeline > li:nth-child(even) .cbp_tmicon {
    display: none;
  }

  .fullWidth {
    width: 100%;
    display: block;
    float: none;
    padding: 0;
    min-height: 1px;
    height: 100%;
    position: relative;
  }

  .cbp_tmtimeline > li .cbp_tmlabel {
    width: 100%;
  }

  .cbp_tmtimeline li {
    margin-bottom: 0px;
    position: relative;
  }

  .cbp_tmtimeline {
    margin: 0px 15px 0px 15px;
  }

  .sectionClass {
    padding: 25px 0px 25px 0px;
  }

  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 15px 0 15px 0;
    padding: 10px;
  }

  .#{$prefix}-fs-4 {
    font-size: 15px;
  }

  .#{$prefix}-fs-5 {
    font-size: 12px;
  }
}

/* Portrait iPad Pro */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .#{$prefix}-intelligent-insights {
    font-size: 38px;
  }
  .subline:before {
    top: -23px;
  }
}
/* Big Desktop width till 1920px ----------------------------------- */
@media (min-width: 1920px) {
  .image-intelligent-insights {
    position: absolute;
    right: 10px;
    margin-top: 45%;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    height: 56px;
    width: 56px;
  }
  .intelligent_insights_first > .splide > .position-relative {
    z-index: 1;
    position: absolute !important;
    width: 100%;
    bottom: 5%;
  }
  .img-insight {
    width: 100px !important;
    height: 200px !important;
  }
  .achievement-img {
    border-bottom-left-radius: 90px;
    border-top-right-radius: 12px;
    border-left: 4px solid #f56620;
    border-bottom: 4px solid #f56620;
    height: 150px;
    object-fit: cover;
  }
  .responsive-bg-w-text {
    display: flex;
    height: 0;
    padding-bottom: 85% !important;
    align-items: center;
    justify-content: center;
    line-height: 1.3;
  }
  .responsive-bg-w-text > .header {
    display: inline;
    margin-top: 70%;
    font-size: calc(1em + 1rem) !important;
    font-weight: bold;
    text-align: left;
    padding: 0 10%;
    color: var(--white);
  }
  .responsive-bg-w-text_2 {
    display: flex;
    height: 0;
    padding-bottom: 35% !important;
    align-items: center;
    justify-content: center;
    line-height: 1.3;
  }
  .responsive-bg-w-text_2 > .header_2 {
    display: inline;
    margin-top: 35% !important;
    font-size: calc(0.7em + 1rem) !important;
    font-weight: bold;
    text-align: left;
    padding: 0 10%;
    color: var(--primary);
  }
  .intelligent_insights_two {
    background: #ffffff;
    border-radius: 12px;
    height: 40vh;
  }
}
/* mobile width till 480px ----------------------------------- */
@media (max-width: 480px) {
  .subline:before {
    top: -43px;
  }
}

/* Nav Tab */
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #0000 !important;
  border-bottom: 3px solid var(--primary) !important;
  border: 0;
  color: var(--primary);

  a {
    border: 0;
    color: var(--primary);
  }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link a {
  border: 0;
  color: var(--secondary);
  text-decoration: none;
}

.nav-tabs .nav-link {
  border: 0;
  color: var(--secondary);
}

.#{$prefix}-btn-ga-pre {
  background: var(--white);
  border: 1px solid var(--primary);
  padding: 12px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 35px;
  color: var(--primary);
}

.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

table.table.dataTable > :not(caption) > * > * {
  background-color: white;
}



table.table.dataTable > tbody > tr {
  background-color: transparent;
  font-weight: 600 !important;
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--white) !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

//preused classes fixes

.sm-graph-modal-legend-text,
.soi-kanban-inner-legend-block :nth-child(2) {
  font-weight: normal !important;
  margin-right: 0.5rem;
}

.graph-block span {
  font-weight: 500 !important;
  max-width: 2rem;
  font-size: 1.5rem;
}

.thumbs-wrapper ul {
  display: none;
}
.tracker-right {
  height: 47px;
  object-fit: cover;
  width: 100%;
}

/* NM Filter Popup */
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100% !important;
  border: 1px solid #ced3d8 !important;
  padding: 0.2rem;
  border-radius: 5px;
}

.calender-div {
  position: relative;
}

.calender-divvv {
  margin-top: -10px;
}

.calender-icon {
  position: absolute;
  top: 0.6rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  color: #aaa;
}

.calender-icon-v2 {
  position: absolute;
  top: 0.2rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  color: #aaa;
}

.calendar-position {
  right: 2rem;
}

.form-group.calender-div select {
  width: 100%;
  border-color: rgba(0, 0, 0, 0.2);
  height: 2rem;
}

//common modals design
.modal-footer-btn {
  @extend .d-flex-center;
  gap: 1rem;
  padding: 0.7rem 0 ;
  margin-top: 0.2rem;
}
.modal-controls button {
  padding: 6px 12px;
  border-radius: 8px;
  font-weight: 500;
  border: 0;
}

.modal-controls button:first-child {
  background: rgba(211, 211, 211, 0.9);
  color: #000000;
  margin-right: 10px;
}

.modal-controls button:last-child {
  background: #ffffff;
  color: var(--primary);
}

.time_picker_modal_header,
.time_picker_modal_footer,
.timezone_picker_modal_header {
  height: 60px !important;
  line-height: 60px !important;
  font-size: 2em !important;
}

.outside_container.active {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.outside_container.active .modal_container {
  position: relative;
  top: 20vh;
  width: 350px;
  margin: 0 auto;
}

.outside_container .modal_container .time_picker_button {
  background: #fff;
}

.picker_container {
  margin: 0 auto !important;
}

.buttons_wrapper {
  margin: 15px 0 0 !important;
  color: #000 !important;
  text-align: center !important;
  float: none !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
}

.react_times_button .wrapper {
  text-transform: uppercase !important;
  color: #000 !important;
  color: #000 !important;
}

.splide__pagination .is-active {
  background-color: var(--primary)!important;
}

/* Responsive img */
.responsive {
  width: 100%;
  height: auto;
}

/* Responsive background */
.responsive-bg {
  height: 0;
  padding-bottom: 66.66%; /* = image.height / image.width * 100% */
}

.responsive-bg-w-text {
  display: flex;
  height: 0;
  padding-bottom: 60%; /* = image.height / image.width * 100% */
  align-items: center;
  justify-content: center;
  line-height: 1.3;
}

.responsive-bg-w-text > .header {
  display: inline;
  margin-top: 70%; /* the same as side ratio above */
  font-size: calc(1em + 0.5rem);
  font-weight: bold;
  text-align: left;
  padding: 0 10%;
  color: var(--white);
}
.responsive-bg-w-text_2 {
  display: flex;
  height: fit-content;
  // padding-bottom: 16%;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
}
.responsive-bg-w-text_2 > .header_2 {
  display: inline;
  margin-top: 5%;
  font-size: calc(0.7em + 0.5rem);
  font-weight: bold;
  text-align: left;
  padding: 0 5%;
  color: var(--primary);
}
.splide__slide {
  height: auto !important;
  white-space: unset!important;
}
.img-insight {
  width: 100px;
  height: 130px;
}
/* ---------Checkbox----------- */
.check-box {
  display: inline-block;
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.7rem;
  cursor: pointer;
  font-size: 0.9rem !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 3rem;
  color: #555;
}

.check-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark1 {
  position: absolute;
  top: 0.1rem;
  left: 0;
  height: 1.2rem !important;
  width: 1.2rem !important;
  background-color: #fff;
  border: 0.12rem solid rgba(0, 0, 0, 0.4) !important;
  border-radius: 1rem;
  padding-left: 0 !important;
}

.check-box:hover input ~ .checkmark1 {
  background-color: #ccc;
}

.check-box input:checked ~ .checkmark1 {
  background-color: #fff;
  border: 0.12rem solid var(--primary) !important;
  height: 1.2rem !important;
  width: 1.2rem !important;
}

.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

.check-box input:checked ~ .checkmark1:after {
  display: block;
}

.check-box .checkmark1:after {
  left: 0.3rem;
  top: 0.05rem;
  width: 0.25rem;
  height: 0.6rem;
  border: solid var(--primary);
  border-width: 0 0.08rem 0.08rem 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.horizontal-nav-tab-wrapper {
  display: flex;
  justify-content: space-between;
  list-style: none;
  background: rgba(224, 224, 224, 0.5);
  box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
    inset 0px 2px 4px rgba(97, 97, 97, 0.2);
  border-radius: 14px;
  color: #616161;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: auto;
}

.horizontal-nav-tab-wrapper .tab-item {
  padding: 8px;
  align-self: center;
  font-size: 16px !important;
  margin: 5px;
  white-space: nowrap;
  cursor: pointer;
  a {
    display: flex;
    align-items: center;
  }
}

.horizontal-nav-tab-wrapper .tab-item a:hover {
  color: inherit !important;
}

.horizontal-nav-tab-wrapper .tab-active {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(97, 97, 97, 0.2),
    0px 2px 4px rgba(97, 97, 97, 0.2);
  border-radius: 12px;
  color: var(--primary);
  font-weight: 600;
  transition: background-color 0.5s ease-in-out;
}
// .form-control {
//   margin-bottom: 1rem;
// }

.data-table-wrapper tr th {
  min-width: 8rem;
  max-width: 10rem;
}
.data-table-wrapper tr td {
  text-align: left;
}

/*----Search UA/CS screen Modal Popup--*/
.reportPopup.searchPopup .modal-dialog {
  max-width: 40rem !important;
}

// Salseforce forms fixes
.slds-listbox li {
  margin-bottom: 0;
}

.salseforce-image-perview li {
  width: 100%;
}
.slds-input,
.slds-combobox__input {
  font-size: 0.9rem !important;
}
.slds-form-element__label {
  font-weight: 700;
}
.slds-listbox {
  padding-left: unset !important;
}
.fishbon-chart-wrapper .causes .causesGroup .causeContent .cause {
  font-size: 0.6rem;
  font-weight: 600;
  line-height: 0.8rem;
  padding: 10px 10px;
  width: 85%;
  cursor: pointer;
}
.fishbon-chart-wrapper .effect {
  height: max-content;
  align-self: center;
}

.fishbon-chart-wrapper .blueBorder {
  background: rgba(217, 217, 217, 0.4);
  border-bottom: 3px solid #d81313 !important;
  border-radius: 3px;
}
.common-check-box-layout label {
  color: #000000;
  font-weight: 500;
  font-size: 0.7rem;
  margin-left: 6px;
  margin-bottom: 0px;
}

.common-check-box-layout input {
  height: 18px;
  width: 20px;
}
/* -----submit modal popup-------- */
.submit-modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.slds-dropdown__item {
  margin-bottom: unset !important;
}

.submit-modal .modal-header,
.delete-modal .modal-header {
  background: #fff;
  position: relative;
  padding: 1rem;
}
.submit-text {
  text-align: center;
  color: #000;
  font-size: 1.1rem;
  margin: 1rem 0 2rem;
}

.red-btn {
  background: #f4f4f4 !important;
  border: 0.08rem solid var(--primary) !important;
  box-shadow: 0.05rem 0.05rem 1rem rgba(0, 0, 0, 0.25) !important;
  border-radius: 2rem !important;
  outline: none;
  color: var(--primary) !important;
  min-width: 8rem;
  text-transform: uppercase;
  font-weight: 600 !important;
  height: 2.5rem;
  font-size: 1rem !important;
  padding: 0 1rem !important;
  // margin-top: 2rem;
}

.red-btn:hover {
  background: var(--primary) !important;
  border: 0.08rem solid var(--primary) !important;
  box-shadow: 0.05rem 0.05rem 1rem rgba(0, 0, 0, 0.25) !important;
  border-radius: 2rem !important;
  outline: none;
  color: #fff !important;
  min-width: 8rem;
  text-transform: uppercase;
  font-weight: 600 !important;
  height: 2.5rem;
  font-size: 1rem !important;
  cursor: pointer;
}
.horizontal-timeline-wrapper {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
  position: relative;
  min-height: 300px;
}

.horizontal-timeline-wrapper .timeline-header {
  background: #fff;
  color: #5b5454;
  position: absolute;
  top: -12px;
  left: 20px;
  padding: 0px 11px;
}

.horizontal-timeline-wrapper .horizontal-timeline-wrapper .timeline-header {
  background-color: transparent !important;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}
.slds-form-element__label abbr[title] {
  text-decoration: unset;
}
.user-badge {
  display: flex;
  align-items: center;
  background: rgba(238, 238, 238, 0.68);
  border-radius: 8px;
  padding: 10px 8px;
  width: 100%;
}

.user-badge .user-avatar {
  width: 1.5rem;
  height: 1.5rem;
}

.svg-icons {
  width: 32px !important;
  height: 32px !important;
}

.common-dropdown-layout select {
  height: 2rem !important;
  /* font-size: 16px !important; */
  border: 2px solid #eeeeee !important;
  border-radius: 6px !important;
  color: #000000 !important;
  font-weight: 400;
  text-align: left;
  line-height: unset !important;
}

.common-dropdown-layout-2 select {
  height: 2rem !important;
  border: 2px solid #eeeeee !important;
  border-radius: 6px !important;
  color: #000000 !important;
  font-weight: 400;
  text-align: center;
  background-position-x: 90%;
}
.datepicker__month abbr {
  text-decoration: none;
}
.datepicker__month th {
  color: #444 !important;
}
.slds-dropdown__item > a {
  text-decoration: none;
}

.dropdown__list {
  padding-left: 0;
}
.bg-gary {
  background-color: rgba(196, 196, 196, 0.4);
  padding: 1rem 1.5rem;
  margin: 0 -1.5rem 1rem;
}

.slds-carousel__image {
  height: 8rem;
}
.slds-carousel__image > img {
  height: inherit;
}
.slds-carousel__panels,
.carousel-slds-height,
.slds-carousel__panel {
  width: 100%;
  max-width: unset !important;
  min-width: unset !important;
}
.display-percentage-wrap canvas {
  width: 10rem !important;
  height: 5rem !important;
}

.user-icon {
  @extend .#{$prefix}-profile-img;
}
.icon-btn {
  background-color: transparent;
  border: 0;
  &:hover {
    background-color: transparent;
    border: 0;
  }
}

#misModule {
  .rdt_TableHeadRow {
    #popover-heading {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
  .slds-card__header {
    border-bottom: 1px solid #dee2e6;
    padding: 1rem 0.9rem;
  }
  font-weight: 700;
  .slds-text-heading_small {
    font-weight: 700;
  }
  table th,
  table td {
    border: 1px solid #dee2e6;
  }
}
#auditSideMenu {
  .#{$prefix}-primary {
    svg {
      g {
        path {
          stroke: #fff;
        }
      }
    }
  }
}

//Pagination Css
.pagination-map {
  ul {
    .page-item {
      a {
        color: #000000;
      }
    }
  }
}

// Datatable library styles

.rdt_TableHeadRow {
  background-color: var(--primary) !important;
  color: var(--bg) !important;
  border-radius: 0.375rem !important;
  font-size: 0.9rem;
  font-weight: 500;
}

.rdt_ExpanderRow .rdt_TableHeadRow {
  background-color: #a0a0a4 !important;
  min-height: 32px !important;
}

#react-data-tablecomponent {
  nav {
    .sc-lllmON {
      display: none;
    }
    .bueHSC {
      margin: 0;
    }
  }
}
.svg-white {
  svg {
    path {
      fill: var(--lightgray);
    }
  }
}

//forms ui

.user-display-box {
  background: #ffffff;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.25);
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.user-display {
  padding-bottom: 0rem;
  border-bottom: 0.07rem solid #ccc;
  margin-top: 1.5rem;
}

.user-display.last-user-box {
  border-bottom: 0 !important;
}

.user-display .form-group {
  margin-bottom: 1rem !important;
}

.user-display .user-title {
  font-size: 1.15rem;
  line-height: 1.3rem;
  font-weight: 500;
  color: var(--primary);
}

.user-view .publish-btn {
  text-align: center;
  margin: 1.5rem 0 2rem;
}

/* --- Add Section---- */
.section-btn {
  text-align: right;
  // margin-bottom: 0.8rem;
}

.section-btn .icon-style {
  width: 1rem;
  height: 1rem;
}

.section-view,
.section-dynamic {
  position: relative;
}

.section-view .first-box-dynamic {
  margin-top: 3rem !important;
  border-radius: 0 0.5rem 0.5rem !important;
}

.box-for-section-head {
  border-radius: 0 0.5rem 0.5rem !important;
}

.section-dynamic .section-head {
  position: absolute;
  top: -2rem;
  left: -1.5rem;
  background-color: var(--primary);
  color: #fff;
  border-radius: 0.5rem 0.5rem 0 0;
}

.section-dynamic .section-head h2 {
  font-size: 1.2rem;
  padding: 0 1rem;
  margin: 0;
  height: 2rem;
  line-height: 2rem;
}

.dynamic-display {
  margin-bottom: 1rem;
}

.que-display h4 {
  font-size: 18px;
  color: #000;
  line-height: 1rem;
  margin: 0 !important;
  padding: 0 !important;
}

.que-display h5 {
  font-size: 1rem;
  color: #000;
  line-height: 1rem;
  margin: 0 !important;
  padding: 0 !important;
}

.que-display span {
  font-size: 13px;
  margin-top: 2px;
  /* color: #545454; */
  color: #000;
}

.que-option {
  padding: 1rem 0 0;
}

.dynamic-display .box-dynamic {
  border: 0;
  border-radius: 0.5rem;
}

.video-preview {
  display: flex;
}

.publish-btn {
  text-align: center;
}

.publish-btn button {
  margin: 0 1rem;
}

.publish-btn button.red-btn {
  box-shadow: none !important;
  background-color: #fff !important;
}

.publish-btn button.red-btn:hover {
  background-color: var(--primary) !important;
}

.section-btn .check-box {
  margin-right: 1rem;
  top: 0.2rem;
}

.image-preview {
  margin-bottom: 0.5rem;
}

.image-preview .preview-box {
  width: 7rem;
  height: 7rem;
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  border: 0.1rem solid #ccc;
  box-shadow: 0.02rem 0.02rem 0.5rem rgba(0, 0, 0, 0.126);
  border-radius: 0.5rem;
  vertical-align: top;
}

.image-preview .preview-box.last {
  margin-right: 0 !important;
}

.image-preview .preview-box img {
  max-width: 100%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  top: 50%;
  position: relative;
  border-radius: 0;
}

/* ----------Radio Classes--------- */
.radio-box {
  display: block;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0.7rem;
  cursor: pointer;
  font-size: 0.9rem !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-box.gu-transit {
  box-shadow: 0 0.1rem 0.5rem var(--primary);
}

.gu-transit .box-dynamic {
  box-shadow: 0 0.1rem 0.5rem var(--primary);
}

.radio-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0.1rem;
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  background-color: #fff;
  border-radius: 50%;
  border: 0.075rem solid rgba(0, 0, 0, 0.6);
  padding-left: 0 !important;
}

.radio-box:hover input ~ .checkmark {
  background-color: #eee;
}

.radio-box input:checked ~ .checkmark {
  background-color: #fff;
  border: 0.12rem solid var(--primary);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-box input:checked ~ .checkmark:after {
  display: block;
}

.radio-box .checkmark:after {
  top: 0.22rem;
  left: 0.23rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: var(--primary);
}
/* ---------Checkbox----------- */
.check-box {
  display: inline-block;
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.7rem;
  cursor: pointer;
  font-size: 0.9rem !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 3rem;
  color: #555;
}

.check-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark1 {
  position: absolute;
  top: 0.1rem;
  left: 0;
  height: 1.2rem !important;
  width: 1.2rem !important;
  background-color: #fff;
  border: 0.12rem solid rgba(0, 0, 0, 0.4) !important;
  padding-left: 0 !important;
}

.check-box:hover input ~ .checkmark1 {
  background-color: #ccc;
}

.check-box input:checked ~ .checkmark1 {
  background-color: #fff;
  border: 0.12rem solid var(--primary) !important;
  height: 1.2rem !important;
  width: 1.2rem !important;
}

.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

.check-box input:checked ~ .checkmark1:after {
  display: block;
}

.check-box .checkmark1:after {
  left: 0.3rem;
  top: 0.05rem;
  width: 0.25rem;
  height: 0.6rem;
  border: solid var(--primary);
  border-width: 0 0.08rem 0.08rem 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* --------File Upload---------- */
.file-upload {
  position: relative;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.file-upload__label {
  display: block;
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 4rem;
  min-width: 11rem;
  text-align: center;
  border: 0.08rem solid #efebeb;
  font-size: 1rem;
}

.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1rem;
  width: 0;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.file-format {
  display: inline-block;
  margin: 0 1rem;
}

.file-format p {
  display: inline-block;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.3);
  margin: 0;
  padding: 0;
}

.file-format img {
  height: 1.3rem;
  width: 1.3rem;
  margin: 0 0.5rem;
}

/* ---------Create Logic------------ */
.logic-section,
.logic-view {
  background-color: rgba(196, 196, 196, 0.1);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem 0.5rem;
}

.logic-section h5 {
  color: var(--primary);
  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 0;
}

.logic-view h6 {
  color: var(--primary);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  padding: 0;
  line-height: 1.5rem;
}

.fa option {
  font-weight: normal;
}

/* -----------Add Images------------- */
.browse-image {
  margin-bottom: 1.5rem;
}

.browse-image img {
  height: 15rem;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.25);
  max-width: 100%;
}

.browse-image .video-icon-style {
  position: absolute;
  top: 16.5rem;
  left: 16rem;
  color: #ffffff;
  width: 1.7rem;
}

/* ----display que view---- */
p.gray-para {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.4);
  margin: 0.3rem 0;
  line-height: 1.2rem;
}

p.gray-para2 {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.4);
  margin: 0.4rem 0;
  line-height: 1.2rem;
}
.text-overflow-ellipsis {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 12rem;
}

//Template Css
#templateDetail {
  .box-dynamic {
    @extend .#{$prefix}-white-box;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  .checkmark {
    border: 0 !important;
  }
  .form-check-input:disabled,
  .form-check-label {
    opacity: 1;
    color: var(--secondary);
  }
}

// Create-Form
.create-form-dynamic {
  .box-dynamic {
    @extend .#{$prefix}-white-box;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  .section-btn {
    svg {
      color: var(--primary);
    }
  }
  .box-dynamic-bottom {
    position: absolute;
    bottom: 1.5rem;
    right: 1rem;
  }

  .box-dynamic-bottom .icon-style {
    width: 1rem;
    height: 1rem;
  }
  .dropbtn {
    width: 100%;
    border: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      color: var(--primary) !important;
      path {
        fill: var(--primary) !important;
      }
    }
  }
  .dropbtn img,
  .dropdown-content img {
    height: 1rem;
    width: 2rem;
    margin-right: 0.5rem;
    vertical-align: sub;
    border-right: 0.05rem solid rgba(0, 0, 0, 0.1);
    padding-right: 0.5rem;
  }

  .dropbtn1 img,
  .dropdown-content1 img {
    height: 1rem;
    width: 8rem;
    margin-right: 0.5rem;
    vertical-align: sub;
    border-right: 0.05rem solid rgba(0, 0, 0, 0.1);
    padding-right: 0.5rem;
  }

  .dropbtn .fa-angle-down {
    font-size: 1.1rem;
    position: relative;
    top: 0.1rem;
    float: right;
  }

  .dropdown {
    position: relative;
    display: block;
  }

  .dropdown1 {
    position: relative;
    display: block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: -0.1rem;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 0.5rem 0;
    max-height: 18rem;
    overflow: auto;
  }

  .dropdown-content1 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 70rem;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }

  .dropdown-content a {
    color: black;
    padding: 0.5rem;
    text-decoration: none;
    display: block;
    font-size: 0.9rem;
  }

  .dropdown-content1 a {
    color: black;
    padding: 0.5rem;
    text-decoration: none;
    display: block;
    font-size: 0.9rem;
  }

  .dropdown-content a:hover {
    background-color: var(--primary);
    color: #fff !important;
    svg {
      stroke: var(--ghostwhite) !important;
      path {
        fill: var(--ghostwhite) !important;
      }
    }
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content1 a:hover {
    background-color: var(--primary);
    color: #fff !important;
  }
}

.active-icon {
  svg {
    // stroke: var(--primary);
    path {
      fill: var(--primary);
    }
  }
}
.i-frame-radious {
  iframe {
    border-radius: 12px;
  }
}

.#{$prefix}-pagination-wrapper {
  ul {
    gap: 0.25rem;
    .active {
      a {
        background-color: var(--primary);
        color: var(--ghostwhite);
        border-radius: 50% !important;
      }
    }
    li {
      a {
        padding: 0.5rem 1rem !important;
        text-decoration: none;
        color: var(--secondary);
      }
    }
  }
}

.user-list {
  margin-bottom: 1rem;
}

.user-list img {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  display: inline-block;
  border: 0.1rem solid #ddd;
  background: #f7f7f7;
}

.user-list h6 {
  font-size: 1rem;
  color: var(--primary);
  display: inline-block;
  margin-bottom: 0;
  line-height: 1rem;
  margin-left: 1rem;
  width: 75%;
  padding-top: 1rem;
  font-weight: 600;
}

.user-list .delete-icon {
  font-size: 1.1rem;
  color: #ffa292;
}

.user-list .add-btn {
  padding: 0.3vw 0 0;
  height: 2.2vw;
  line-height: inherit !important;
  width: 2.2vw;
  text-align: center;
  border-radius: 50%;
  border: 0 !important;
}

.user-list .add-btn .plus-icon {
  margin: 0;
  padding: 0;
  display: inline-block;
  float: none;
}

//chat messages styles
.chat-history {
  padding: 0px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: 35vh;
}
.chat-history .message-data {
  margin-bottom: 15px;
}
.chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
}
.chat-history .message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 10px;
  width: 95%;
  position: relative;
}
.chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #86bb71;
  border-width: 10px;
  margin-left: -10px;
}
.chat-history .my-message {
  background: #86bb71;
}
.chat-history .other-message {
  background: #94c2ed;
}
.chat-history .other-message:after {
  border-bottom-color: #94c2ed;
  left: 93%;
}
.chat-message {
  padding: 30px;
}
.chat-message textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  font: 14px/22px "Lato", Arial, sans-serif;
  margin-bottom: 10px;
  border-radius: 5px;
  resize: none;
}
.chat-message .fa-file-o,
.chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}
.chat-message button {
  float: right;
  color: #94c2ed;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #f2f5f8;
}
.chat-message button:hover {
  color: #75b1e8;
}

.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86bb71;
}

.offline {
  color: #e38968;
}

.me {
  color: #94c2ed;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

table thead {
  thead {
    tr {
      th {
        color: var(--primary)!important;
      }
    }
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
ul {
  list-style-type: none;
}
.clide-v2-lightgray:after {
  border-bottom-color: var(--lightgray) !important;
}
.clide-v2-table-value-circle {
  width: 40px;
  height: 40px;
  padding: 5%;
  border: 4px solid var(--primary);
  border-radius: 45px;
}

//DataTable

.sc-eDvSVe {
  display: flex;
  justify-content: center;
  a {
    color: var(--primary)!important;
  }
  select {
    background:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") right .75rem center/8px 10px no-repeat!important;
  }
}

/* Invite User */
.search-section {
  padding: 0 0 2vw;
  position: relative;
}

.search-section input[type="text"] {
  padding: 0.5rem;
  font-size: 0.95vw;
  border: 0;
  border-bottom: 0.02vw solid rgba(0, 0, 0, 0.25) !important;
  float: left;
  width: 100%;
  background: #fff;
  position: relative;
  padding-right: 3vw !important;
}

.search-section input[type="text"]:focus {
  box-shadow: none !important;
  outline: none !important;
}

.search-section button {
  font-size: 1.1rem;
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  box-shadow: none !important;
  outline: none !important;
}

.search-section button:hover {
  background: transparent;
}

.search-section::after {
  content: "";
  clear: both;
  display: table;
}

.invite-user-section.rd-left-bottom {
  padding-top: 0 !important;
}

.invite-user-section .user-list,
.rd-right-content .user-list {
  display: flex;
}

.invite-user-section .user-list h6 {
  width: 83%;
}

.user-list .plus-icon {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.25);
  float: right;
  padding-right: 0.5rem;
  padding-top: 0.4rem;
}

.user-list .plus-icon.active {
  color: var(--primary);
}
.modal-header {
  
  position: relative;
  padding: 1rem;
}
.modal-header .modal-btn {
  border: 0 !important;
  background-color: var(--primary);
  box-shadow: none !important;
  outline: none !important;
  
  position: absolute;
  top: 0.7vw;
  right: 3vw;
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff !important;
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  
  padding-right: 1rem;
  margin: 0 !important;
  line-height: 1.5vw !important;
}

.inputfile {
  width: 8vw;
  height: 1vw;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile-section {
  width: 11vw;
  height: 2.5vw;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
}

.image-custom {
  background-color: #edf6ff;
  width: 2.2vw;
  height: 2.2vw;
  display: inline-block;
  padding-left: 0.5vw;
}

//Global filter
.#{$prefix}-dropdown-content {
  display: none;
  position: absolute;
  top: 2rem;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0.5rem 0;
  max-height: 18rem;
  overflow: auto;
  width: 21vw !important;
}

.#{$prefix}-dropbtn img,
.#{$prefix}-dropdown-content img {
  // height: 1vw;
  // width: 2vw;
  margin-right: 0.5vw;
  vertical-align: sub;
  border-right: 0.05vw solid rgba(0, 0, 0, 0.1);
  padding-right: 0.5vw;
}

.#{$prefix}-dropdown {
  position: relative;
  display: block;
}

.#{$prefix}-dropdown:hover .#{$prefix}-dropdown-content {
  display: block;
}

.#{$prefix}-location-width {
  border-left: 4px solid $primary;
  width: 10vw !important;
  margin-left: 20px;
  margin-top: 5px;
}

.#{$prefix}-dropbtn {
  background-color: #fff !important;
  color: #000;
  padding: 0.7vw;
  font-size: 0.9vw;
  border: none;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
}

.#{$prefix}-dropbtn .fa-angle-down {
  font-size: 1.1rem;
  position: relative;
  top: 0.1rem;
  float: right;
}

.loc-head {
  border-bottom: 0.05vw solid rgba(0, 0, 0, 0.1);
}

.loc-head h5 {
  display: inline-block;
  font-size: 1vw;
  color: rgba(0, 0, 0, 0.4);
  text-align: left;
  padding: 0.5vw 1vw 0;
  /* border-bottom: 0.05vw solid rgba(0, 0, 0, 0.1); */
}

.loc-mid {
  padding: 0.5vw 1vw;
  text-align: left;
}

.loc-mid-pd {
  padding: 1vw !important;
}

.loc-mid .form-group label {
  font-weight: 500;
  font-size: 0.9vw;
  line-height: 1.4vw;
  color: rgba(0, 0, 0, 0.8);
}

.loc-mid .checkmark1 {
  position: absolute;
  left: 0;
  background-color: #fff;
  border: 0.075vw solid rgba(0, 0, 0, 0.4);
  padding-left: 0 !important;
}

.loc-mid .check-box .checkmark1:after {
  left: 0.3vw;
  top: 0.08vw;
  width: 0.3vw;
  height: 0.6vw;
  border: solid var(--primary);
  border-width: 0 0.1vw 0.1vw 0;
  transform: rotate(45deg);
}

.loc-mid .check-box {
  display: block;
  position: relative;
  padding-left: 2vw;
  margin-bottom: 0.7vw;
  cursor: pointer;
  font-size: 0.9vw !important;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 3vw;
}

.loc-mid .btn {
  padding: 0 !important;
}

.btn-link {
  display: block;
  color: #000;
  text-decoration: none;
  border-bottom: 0.05vw solid #eee;
  display: block;
  border-radius: 0;
  width: 100%;
  text-align: left;
  margin-bottom: 0.5vw;
}

.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
  text-decoration: none;
  color: #000;
  border-bottom: 0.05vw solid #eee;
}

.btn-link h6 {
  font-size: 0.9vw;
  color: #000;
  font-weight: 600;
  padding-left: 2vw;
  margin-top: -0.55vw;
}

.project-submit-box {
  text-align: center;
}

.project-btn {
  background: var(--primary) !important;
  border: 0.08vw solid var(--primary) !important;
  box-shadow: none !important;
  border-radius: 2vw !important;
  outline: none;
  color: #fff !important;
  min-width: 8vw;
  text-transform: uppercase;
  font-weight: 600 !important;
  height: 2vw;
  font-size: 1vw !important;
  padding: 0 1vw !important;
}

.refresh-button {
  margin-left: 0.4rem;
  opacity: 0.6;
}

.output-text {
  text-wrap: wrap;
}

.blur-3 {
  filter: blur(3px);
}

.white-space-nowrap{
  
    white-space: nowrap;

}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.7);
}

.loader .loader-img {
  margin: 0 auto;
  display: block;
  height: 8vw;
  margin-top: 20%;
}

//report
.custom-input {
  // font-size: 1rem !important;
  font-size: inherit;
  padding-left: 0.5rem;
}

/* form  */
.inputErrorMsg {
  font-size: 0.9vw;
  color: red;
  margin-top: 0.2vw;
}

span.star {
  color: red;
  padding-left: 6px;
  font-size: 20px;
}

.#{$prefix}-scroll-bar-skill {
  height: 43vh !important;
  overflow: auto;
}

.form-select-dropdown {
  height: calc(1.875rem + 2px);
  width: 100%;
  border: 1px solid var(--slds-c-select-color-border, var(--sds-c-select-color-border, var(--slds-g-color-border-base-4, #c9c9c9)));
  border-radius: var(--slds-c-select-radius-border, var(--sds-c-select-radius-border, .25rem));
  background-color: var(--slds-c-select-color-background, var(--sds-c-select-color-background, var(--slds-g-color-neutral-base-100, #fff)));
  color: var(--slds-c-select-text-color, var(--sds-c-select-text-color));
  box-shadow: var(--slds-c-select-shadow, var(--sds-c-select-shadow));
  transition: border .1s linear, background-color .1s linear;
  padding: 0 1rem;
}

.scoring-text {
  padding-left: 1rem;
  margin-bottom: 0;
  font-size: 0.9rem !important;
}

// live dashboard filter modal

.home-filter-modal .All-reports {
  margin: 0 2vw;
}

.home-filter-modal .All-reports:last-child {
  margin-bottom: 3vw;
}

.home-filter-modal .All-reports h2 {
  font-size: 1.1vw;
  margin: 2vw 0 0;
  font-weight: 600;
  position: relative;
}

.home-filter-modal .All-reports h2 span {
  position: absolute;
  top: 0;
  right: 0;
}

.home-filter-modal .checkmark1 {
  top: 0;
}

.home-filter-modal .check-box {
  margin: 0;
  font-size: 1vw !important;
  padding-left: 1.7vw;
  color: #000;
}

.home-filter-modal .Inner-field-report span {
  width: 11vw;
  display: inline-block;
  margin: 0;
}

.home-filter-modal .modal-dialog {
  max-width: 50.5vw !important;
}

.home-filter-modal .Inner-field-report span.report-checkbox {
  margin: 1vw 0;
}

.home-filter-modal .nav-item {
  margin: 0;
  position: relative;
  top: -1px;
}

.home-filter-modal .nav-tabs {
  border-bottom: 2px solid #ccc !important;
  margin: -15px -13px 15px;
}

.home-filter-modal .nav-tabs .nav-link {
  margin: 0;
  padding: 0.2vw 3.5vw 0 !important;
  border-bottom: 2px solid #fff;
}

.home-filter-modal .tab-content .nav-tabs {
  margin: 0;
  text-align: center;
  display: block;
  box-shadow: none;
}

.home-filter-modal .tab-content .nav-tabs .nav-item {
  display: inline-block;
  margin: 0;
  position: relative;
  top: -1px;
}

.home-filter-modal .tab-content .tab-pane .tab-pane {
  border: 1px solid #ddd;
  border-top: 0;
  margin-top: 0;
  padding-top: 1px;
  overflow: hidden;
}

.home-filter-modal .Inner-field-report.safety-th-repo span {
  display: block;
  width: 100vw;
}

.home-filter-modal .safety-thoughts-filt .All-reports:last-child {
  margin: 3vw 2vw 2vw;
}
.table-wrapper-center tr td {
  text-align: center;
}

.help-filter-dropdown {
  width: 30vw;
  padding: 0 5% 0 5%;
}

.help-video-card {
  border-radius: 4px;
  /* background: #fff; */
  /* box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05); */
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 5px 5px 5px 5px;
  cursor: pointer;
  /* width: 25vw; */
  width: 16vw;
  height: 53vh;
}

.time_picker_preview {
  height: 31px !important;
}

.preview_container {
  line-height: 31px !important;
  height: 31px !important;
}

.preview_container svg {
  top: 3px !important;
}

.react_times_button{
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: 1px solid #ced3d8 !important;
  border-radius: 5px !important;
}

.react_times_button .wrapper {
  color: inherit !important;
}

.padding-l-r {
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
}

.ai-overlay-verify {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2px;
  // left: 2px;
  color: white;
  background-color: rgb(255 255 255 / 100%);
  padding: 0px 2px;
  font-size: 12px;
  font-weight: 600;
  color: rgb(24, 218, 7);
  border: 1.5px solid rgb(24, 218, 7);
  border-radius: 5px;
  overflow: hidden;
}

.ai-overlay-unverify {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2px;
  // left: 2px;
  color: white;
  background-color: rgb(255 255 255 / 100%);
  padding: 0px 2px;
  font-size: 12px;
  font-weight: 600;
  color: rgb(255, 66, 66);
  border: 1.5px solid rgb(255, 66, 66);
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.sm-graph-modal-legend-box {
  width: 0.7vw !important;
  height: 0.7vw !important;
  margin-right: 6px;
  background-color: #ff6d52;
}

.close-btn {
  position: absolute;
  top: 0.2vw;
  right: 0.1vw;
  color: #fff !important;
  border: 0.08vw solid #fff !important;
  background-color: #ff6347 !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 0.8vw !important;
  border-radius: 50% !important;
  padding: 0 !important;
  line-height: 0vw !important;
  width: 1.3vw;
  height: 1.3vw;
}
// /* Global CSS */
// .css-9gakcf-option {
//   background-color: var(--ghostwhite)!important; /* Change this to your desired color */
//   color: var(--secondary)!important; /* Change text color */
// }

// css-9gakcf-option--is-focused {
//   background-color: var(--primary) !important; /* Color when an option is focused */
// }

// css-9gakcf-option--is-selected {
//   background-color: var(--primary) !important; /* Color for selected option */
// }



//global  styles

.modal-header {
  .modal-title{
    color: var(--primary)!important;
    font-size: 1.3rem;
    font-weight: 600;
  }
}

ul {
  li {
    white-space:break-spaces;
  }
}

.w-space-nobreak {
  white-space:break-spaces!important;
}

.w-brake-breakall {
  word-break: break-all !important;
}

.w-space-nowrap {
  white-space:nowrap!important;
}

label{
  white-space:break-spaces!important;
}
.images-section .image-box {
  width: 4.5rem;
  height: 4.5rem;
  display: inline-block;
  border: 0.15rem solid #ffffff;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.126);
  border-radius: 1rem;
  margin-right: 0.5rem;
  vertical-align: top;
  position: relative;
}

.images-section .image-box .btn.btn-outline-link {
  position: absolute;
  right: -0.8rem;
  top: -0.5rem;
  padding: 0 0.5rem;
  font-size: 1rem;
}

.addcam-input {
  border: none;
}

.camera-container {
  width: 18rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.brand-logo1{
  width:7%;
  margin-right: 3%;
  border: 0.1rem solid #fff;
  border-radius:0.5rem;
}

.icon-location-cam {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:5px;
}


.icon-location-cam button{
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 5px;
  width: 25px;
  height: 25px;
}

.sep-cam{
    display: flex;
    align-items: center;
    border-right: 1px solid #e0e0e0;
    padding-right: 10px;
}

.safety-champion-header{
  background-color: #F56620;
  color: #fff;
  font-weight: 500;
  border-radius: 0.7rem 0.7rem 0 0;
  padding: 16px 20px;
}

.st-image-box {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.5vw;
}

.st-image-box .safety-team-img {
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 50%;
  margin-right: 0.5vw;
  border: 0.1vw solid #ffffff;
  box-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.214);
}

.st-text-box {
  display: inline-block;
  border-left: 0.07vw solid rgba(0, 0, 0, 0.1);
  padding-left: 1vw;
}

.st-text-box h6 {
  font-size: 1vw;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.2vw;
  margin-bottom: 0;
}

.rd-box {
  margin-bottom: 1.5vw;
}

.selectList {
  padding: 0;
  text-align: left;
}


.selectList li{
  display: inline-block;
  width: 50%;
  list-style: none;
  text-align: left;
  padding: 0 2%;
}

.selectList li .box {
  background: #ffffff;
  box-shadow: 0 0.1vw 0.5vw #ccc;
  border-radius: 0;
  padding: 0.8vw 1vw;
  margin-bottom: 1vw;
}

.selectList li .box .checkmark,
.selectList li .box .checkmark1 {
  top: 0.6vw;
}

.selectList li .box,
.selectList li .box .check-box {
  margin-bottom: 0;
  width: 100%;
}

.selectList li .st-text-box {
  position: absolute;
  padding-left: 1vw;
  top: 50% !important;
  left: 5.5vw;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
}

.check-box {
  margin-bottom: 0;
  width: 100%;
}

.selectModal .modal-dialog {
  max-width: 55vw !important;
}


.gray-btn {
  background: #fff !important;
  border: 0.08rem solid #b8b8b8 !important;
  box-shadow: 0.05rem 0.05rem 1rem rgba(0, 0, 0, 0.25) !important;
  border-radius: 2rem !important;
  outline: none;
  color: #878787 !important;
  min-width: 8rem;
  text-transform: uppercase;
  font-weight: 600 !important;
  height: 2.5rem;
  font-size: 1rem !important;
  padding: 0 1rem !important;
}

.gray-btn:hover {
  background: #878787 !important;
  border: 0.08rem solid #878787 !important;
  box-shadow: 0.05rem 0.05rem 1rem rgba(0, 0, 0, 0.25) !important;
  box-shadow: none !important;
  border-radius: 2rem !important;
  outline: none;
  color: #fff !important;
  min-width: 8rem;
  text-transform: uppercase;
  font-weight: 600 !important;
  height: 2.5rem;
  font-size: 1rem !important;
  padding: 0 1.5vw !important;
  cursor: pointer;
}

.modal-footer-btn {
  text-align: center;
}

.modal-footer-btn .red-btn {
  display: inline-block;
  margin: 0 0.5vw !important;
}

.modal-footer-btn {
  text-align: center;
  width: 100%;
  border: 0;
}

/* StickyPopup.css */
.sticky-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 30rem;
  background: #fbe9e7; /* Beige background */
  color: #333;
  border: 1px solid #ff5722; /* Border matching the orange button */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  font-family: Arial, sans-serif;
  z-index: 1000;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

.sticky-popup h4 {
  margin: 0 0 8px;
  font-size: 16px;
  color: #ff5722; /* Orange text */
  font-weight: bold;
}

.sticky-popup p {
  margin: 0 0 16px;
  font-size: 14px;
  line-height: 1.5;
}

.popup-button {
  display: block;
  width: 100%;
  padding: 10px 0;
  background: #ff5722; /* Orange background */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.popup-button:hover {
  background: #e64a19; /* Darker orange */
}
