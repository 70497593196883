@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

$prefix: "clide-v2";


//colors
$bg: #F3F4F8;
$lightgray: #EBEBEB;
$gray: #878787;
$primary: #F56620;
$secondary: #343A40;
$white: #ffffff;
$blue: #0A58CA;
$sky: #343A40;
$Neonpink: #F5B849;
$green: #52BB00;
$ghostwhite: #F8F8FB;
$smokewhite: #f1f1f1;
  