#authContainer {

    

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

body{
    font-family: 'Poppins', sans-serif;
    margin: 0;
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: center;
    background: #ffffff;
}


.sign-in-container{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    
}
.sign-column{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50%;
    font-size: 16px;
    height: 100vh;
   
}


.sign-in-title{
    font-weight: 600;
    font-size: 26px;
}
.sign-in-title{
    margin-top: 50px;
}
.s2{
   display: flex;
   align-items: center;
   justify-content: center;
    padding: 50px 0;
    overflow: auto;
    background: #fcfbfa;
    height: 80%;
    width: 750px;
    box-shadow: 0 0 5px #00000061;
    border-radius: 8px;
}
.sign-in-title-alt{
    color: #000;
    
    padding: 10px 0;
}
.sign-buttons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 25px 0;
}
.login-w-button{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 10px;
    border: 1px solid #3676FF;
    background: #fff;
    padding: 10px;
    height: 40px;
    width: 200px;
    border-radius: 5px;
    color: #222222;
    transition: .3s ease all;
    font-size: 14px;
    box-shadow: 0 0 15px #00000020;
}
.login-w-button:hover{
    background: #eef4ff;
    border: 1px solid #a3c1f5;
    box-shadow: 0 0 5px #00000020;
}
.slice-text-c{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
}
.slice-text{
    color: #000;
    background: #fff;
    padding: 10px;
}
.slicer{
    position: absolute;
    height: 1px;
    background: #e5e5e5;
    width: 500px;
    z-index: -1;
}
.intro-content{
    padding: 0 1rem;

}
.input-container{
    display: flex;
    justify-content: center;
    margin: auto;
    gap: 1.8rem;
    flex-direction: column;
   
}
.input-container input, .input-container button{
    padding: 15px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #ff644e;
    box-shadow: 0 0 15px #00000020;
    transition: .3s ease all;
}
.input-container input:focus{
    border: 1px solid #3676FF;
    background: #eff5ff;
}

.input-container button{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff644e;
    color: #fff;
    cursor: pointer;
    transition: .3s ease all;
}
.input-container button:hover{
    box-shadow: 0 0 5px #00000020;
}

.input-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"], input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.s3 {
    padding: 0 5rem;
}

.password-container {
    position: relative;
    width: 100%;
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #007BFF;
}

.alt-f-full{
    color: #000;
    font-size: 14px;
}
.alt-f {
    color: #ff644e;
    font-size: 14px;
    text-decoration: none;
}
.footer-s{
    position: absolute;
    bottom: 30px;
    display: flex;
    gap:10px;
    
}
.footer-s a{
    color: #fff;
}
.sign-column:nth-child(2) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    
  
}
input::placeholder{
    color: #000;
    
}

.intro-p{
    color: #252525;
    
    max-width: 750px;
}

.intro-img{

    height: 500px;
}
.intro-img img {
    max-width: 600px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.canvas-logo{
    background: #fff;
    border-radius: 8px;
    max-width: 50%; 
    margin: 10px auto;

}
.canvas-logo img{
    width: 100%;
}
.intro-title{

    font-size: 1rem;
    padding: 10px 0;
    color: #000;
   
}


.intro-title-alt{
    color: #353535;
    font-size: 16px;
}
.alt-f-full-ch{
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 5px;
    
    color: #000;
}
.alt-f-full-ch input{
    box-shadow: 0 0 0;
}

@media only screen and (max-width: 1100px) {
    body {
        background: #fff;
    }

    .s2 {
        display: flex;
        align-items: start;
        justify-content: center;
        padding: 0px;
        padding: 10px;
        padding-top: 50px;
        padding-bottom: 50px;

        background: #ffffff;
        height: auto;
        width: 100%;
        box-shadow: 0 0 0px #00000061;
        border-radius: 0px;
    }

    .sign-column:nth-child(2) {
        display: none;
    }

    .sign-column:nth-child(1) {
        width: 100% !important;
        height: auto;
    }

    .slicer {
        width: 0px !important;
    }

    .login-w-button {
        width: 100%;
    }

    .input-container {
        display: flex;
        justify-content: center;
        margin: auto;
        gap: 20px;
        flex-direction: column;
        width: 100%;
    }
}
@media only screen and (max-height: 675px) {
    body{
        background: #fff;
    }
    .s2 {
            display: flex;
            align-items: start;
            justify-content: center;
            padding: 0px;
            padding: 10px;
            padding-top: 50px;
            padding-bottom: 50px;
        
            background: #ffffff;
            height: auto;
            width: 100%;
            box-shadow: 0 0 0px #00000061;
            border-radius: 0px;
        }
    // .sign-column:nth-child(2) {
    //     display: none;
    // }
    // .sign-column:nth-child(1) {
    //     width: 100% !important;
    //     height: auto;
    // }
    .slicer{
        width: 0px !important;
    }
    .login-w-button{
        width: 100%;
    }
    .input-container{
    display: flex;
    justify-content: center;
    margin: auto;
    gap: 20px;
    flex-direction: column;
    width: 100%;
}
}

.shareArticle {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding: 15px;
}

.shareSocial {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
  @media (max-width: 767px) {
    flex-flow: column;
  }
  .socialTitle {
    margin: 0 15px 0 0;
    font-size: 20px;
    @media (max-width: 767px) {
      margin-bottom: 15px;
      text-align: center;
    }
  }
  .socialList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    flex-flow: row wrap;
    li {
      margin: 5px;
      &:first-child {
        padding-left: 0;
      }
      a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        text-decoration: none;
        background-color: #999;
        color: #fff;
        transition: .35s;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: top left;
          transform: scale(1) translate(-50%, -50%);
          transition: .35s;
        }
        &:hover {
          i {
            transform: scale(1.5) translate(-50%, -50%);
          }
        }
      }
      &:nth-child(1) a {
        background-color: #135cb6;
      }
      &:nth-child(2) a {
        background-color: #00aced;
      }
      &:nth-child(3) a {
        background-color: #135cb6;
      }
      &:nth-child(4) a {
        background-color: #BD081C;
      }
      &:nth-child(5) a {
        background-color: #1FB381;
      }
      &:nth-child(6) a {
        background-color: #e65940;
      }
    }
  }
}
.logo{
    width: 15%;
    position: absolute;
    top: 5%;
    left: 5%;
}
.logo-img{
    width: 100%;
    border-radius: 12px;
    background: #fff;
    padding: 5px;
    box-shadow: 0 0 5px #787777;
}
.login-title{
    font-weight: 600;
    font-size: 25px;
    color: #e65940;
}
.d-flex{
    display: flex;
}
.login-links{
    font-weight: 600;
    text-decoration: none;
    color: #ff644e;
    padding: 0 2%;
}
.brand-logo{
    width: 20%;
    margin-right: 5%;
}

.shareLink {
  .permalink {
    position: relative;
    border-radius: 30px;
    .textLink {
      text-align: center;
      padding: 12px 60px 12px 30px;
      height: 45px;
      width: 450px;
      font-size: 16px;
      letter-spacing: .3px;
      color: #494949;
      border-radius: 25px;
      border: 1px solid #f2f2f2;
      background-color: #f2f2f2;
      outline: 0;
      appearance: none;
      transition: all .3s ease;
      @media (max-width: 767px) {
        width: 100%;
      }
      &:focus {
        border-color: #d8d8d8;
      }
      &::selection {
        color: #fff;
        background-color: #ff0a4b;
      }
    }
    .copyLink {
      position: absolute;
      top: 50%;
      right: 25px;
      cursor: pointer;
      transform: translateY(-50%);
      &:hover {
        &:after {
          opacity: 1;
          transform: translateY(0) translateX(-50%);
        }
      }
      &:after {
        content: attr(tooltip);
        width: 140px;
        bottom: -40px;
        left: 50%;
        padding: 5px;
        border-radius: 4px;
        font-size: 0.8rem;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        background-color: #000000;
        color: #ffffff;
        transform: translateY(-10px) translateX(-50%);
        transition: all 300ms ease;
            text-align: center;
      }
      i {
        font-size: 20px;
        color: #ff0a4b;
      }
    }
  }
}
.powered-by {
    font-size: 14px;
    padding: 10px 0;
    color: #a39b9b;
}
}