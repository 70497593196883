// OverlayLoader.scss
.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999; // Ensure the loader is above other elements

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); // Light border for background effect
    border-left-color: #f56620; // Use the provided color for the spinner
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
}

// Spinner animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
