@import 'assets/styles/_variables.scss';

/*NM CSS*/
.#{$prefix}-card-box {
  background: #FBFAFF;
  border: 1px solid #A4A1A0;
  border-radius: 12px;
  height: auto;
  overflow: hidden;
}

.#{$prefix}-white-box {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 12px;
}

.#{$prefix}-scroll-bar {
  height: 62vh;
  overflow: auto;
}
.#{$prefix}-ellipsis-text {
  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
  display: inline-block;
  
}
.#{$prefix}-graph-left-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.#{$prefix}-square-span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 5px;
}
.#{$prefix}-name-prefix{
  padding: 2px;
}
.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--white);
  background:var(--primary);
  border: none;
  outline: none;
  border-radius: 50%;
} 
.icon-button__badge {
  position: absolute; 
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background: var(--white);
  color:var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--primary);
}

/*End NM CSS*/

/*NM- CAPA*/

.#{$prefix}-icon{
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 8px;
  border: 1px solid #A4A1A0;
  margin: 0 5px;
}
.#{$prefix}-bg-white{
  background: var(--white)!important;
}
.#{$prefix}-border-color,input{
  border-color: #ced4da;
}
.#{$prefix}-side-color-box{
  width: 7%;
  height: 72vh; 
  text-align: center; 
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.#{$prefix}-side-text{
    writing-mode: vertical-lr;
     -webkit-transform: rotate(-180deg); 
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    white-space: nowrap;
    display: inline-block;
    bottom: 0;
    width: 40%;
    height: 90%;
}
.#{$prefix}-side-button{
    background: var(--sky);
    border: 0;
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 30px;
    color: var(--white);
}
.#{$prefix}-border-radius-capa{
    border-radius: 0 12px 12px 0; 
}
.#{$prefix}-border-radius-12{
  border-radius: 12px; 
}
.#{$prefix}-border-10{ 
    border-left: 10px solid;
}
.#{$prefix}-side-color-capalist{
  width: 100%;  
}
.#{$prefix}-border-top-0{
  border-top-left-radius: 0!important;
  border-top-right-radius: 0!important;
  border-top: 0!important;
}
.#{$prefix}-text-ga{
  line-height: 2;
  color: var(--primary);
  padding: 5% 0;
}
.#{$prefix}-btn-ga{
  background: var(--primary);
  border: 0;
  padding: 12px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 35px;
  color:var(--white);
}
.#{$prefix}-center { 
  position: absolute;
  top: 54%;
  left: 27%;
  transform: translate(-50%, -50%);
  padding: 10px;
  color: var(--gray);
}
.#{$prefix}-bullet {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 50%;
  background: #fff; 
  margin-left: -11px;
  margin-top: 5px;
}
.#{$prefix}-blue{
  border: 2px solid var(--blue);
}
.#{$prefix}-h-300{
  height: 300px 
}

@media screen and (max-width: 600px) {
  .flex-direction-m {
    flex-direction: column;
  }

  .#{$prefix}-side-color-box {
    width: 100% !important;
    text-align: center;
    height: 5vh !important;
    border-radius: 12px;
  }

  .#{$prefix}-side-text {
    writing-mode: revert !important;
    -webkit-transform: rotate(0deg) !important;
    -moz-transform: rotate(0deg) !important;
    -o-transform: rotate(0deg) !important;
    -ms-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
    white-space: nowrap;
    display: block;
    bottom: 0;
    width: 100% !important;
    height: auto;
    padding-top: 10px;
    text-align: center;
  }

  .#{$prefix}-bullet {
    width: 40px !important;
    height: 20px;
    box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
    margin-left: -11px;
    margin-top: 5px;
  }

  .#{$prefix}-h-300 {
    height: 150px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {

  /*CAPA CSS*/
  .flex-direction-m{
    flex-direction: column;
  }
  .#{$prefix}-side-color-box {
    width: 100%!important;
    text-align: center;
    height: 5vh!important;
    border-radius: 12px;
  }
  .#{$prefix}-side-text {
    writing-mode: revert!important;
    -webkit-transform: rotate(0deg)!important;
    -moz-transform: rotate(0deg)!important;
    -o-transform: rotate(0deg)!important;
    -ms-transform: rotate(0deg)!important;
    transform: rotate(0deg)!important;
    white-space: nowrap;
    display: inline-block;
    bottom: 0;
    width: 100%!important;
    height: auto;
    padding-top: 10px;
    text-align: center;
}
}